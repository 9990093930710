import styled from 'styled-components';

export const Body = styled.div`
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 600px;
  margin: 0 auto;
  padding: 70px 0 220px 0;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px 35px 170px 35px;
  }
`;

export const ImageDiv = styled.div`
  text-align: center;
`;
export const ImageStyle = styled.img`
  width: 200px;
  height: auto;
  margin: 0 0 30px 0;
`;

export const HeaderH3 = styled.div`
  padding: 0;
  margin: 0 0 40px 0;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 35px;
  line-height: 42px;
  text-transform: uppercase;
  color: #6e2b8b;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
  text-align: center;
  b {
    font-weight: bold;
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
  i {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0 !important;
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  code {
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    font-family: 'Figtree-Regular', arial, sans-serif;
    padding: 0;
    margin: 0 0 40px 0;
    color: rgba(0, 0, 0, 0.6);
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  u {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 15px;
    text-align: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const HeaderH2 = styled.h2`
  width: 250px;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 60px;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  color: #fec119;
  background: #6e2b8b;
  border-radius: 5px;
  code {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
  }
`;

export const LinkStyle = styled.a`
  text-align: center;
  text-decoration: none;
  color: #6e2b8b;
  transition: all 0.25s ease-out;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #6e2b8b;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
  code {
    color: #6e2b8b;
    margin-bottom: 15px !important;
    :hover {
      color: #6e2b8b;
    }
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;
